<script>
import moment from "moment";
import Switches from "vue-switches";
import {
  required,
  // minLength
} from "vuelidate/lib/validators";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { notificationMethods } from "@/state/helpers";
import Multiselect from 'vue-multiselect';
import * as cloneDeep from "lodash.clonedeep";
import {
  SETUP_OR_GENERATE_2FA_OTP,
  UPDATE_SUB_USER_DETAILS,
  VERIFY_2FA_FOR_CURRENT_USER,
  FETCH_SUB_USER_DETAILS_BY_ID,
} from "@/state/actions.type";
import OtpInput from "@bachdgvn/vue-otp-input";

export default {
  page: {
    title: "PortOne Profile Settings",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    Multiselect,
    Switches,
    Layout,
    PageHeader,
    OtpInput
  },

  data() {
    return {
      output: null,
      twoFAUserDetails: null,
      googleAuthQRUrl: null,
      otpData: {
        otp: "",
      },
      submitted: false,
      verify2FAOtp: false,
    };
  },

  mounted() {
    this.twoFAUserDetails = cloneDeep(this.userDetails);
  },

  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    title() {
      return this.$t("menuitems.payouts.list.profile");
    },

    items() {
      return [
        {
          text: this.$t("menuitems.payouts.list.profile"),
          active: true,
        },
        {
          text: this.$t("menuitems.payouts.list.settings"),
          active: true,
        },
      ];
    },

    userDetails() {
      return this.$store.getters["auth/getCurrentUser"];
    },

  },

  watch: {
    userDetails() {
      this.twoFAUserDetails = cloneDeep(this.userDetails);
    },
  },

  validations: {
    otpData: {
      otp: { required },
    },
  },

  filters: {
    formateDate(value) {
      return moment(value).format("MMMM DD YYYY, h:mm:ss a");
    },
  },

  methods: {
    ...notificationMethods,

    resetOTPData() {
      this.otpData = {
        otp: ""
      };
    },

    // eslint-disable-next-line no-unused-vars
    verifyOTP(e) {
      this.submitted = true;
      console.log("this.$v", this.$v)
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        //
        console.log("otpData", this.otpData);
        const optDetails = {
          type: this.twoFAUserDetails.default_2fa_channel,
          otp: this.otpData.otp,
        };
        this.$store
          .dispatch(`accounts/${VERIFY_2FA_FOR_CURRENT_USER}`, optDetails)
          .then(responseData => {
            this.$store.dispatch(`accounts/${UPDATE_SUB_USER_DETAILS}`, {subUserID: this.twoFAUserDetails.subUserUuid, subUserDetails: this.twoFAUserDetails} )
            this.verify2FAOtp = false;
            console.log("Success of VERIFY_2FA_FOR_CURRENT_USER", responseData);
            this.resetOTPData();
          })
          .catch(error => {
            this.resetOTPData();
            console.log("Error while verifying OTP", error);
          })

      }
      this.submitted = false;
    },

    resetNotificationError() {
      this.$store.dispatch("notification/clear");
    },

    openOtpModel() {
      this.resetOTPData();
      this.resetNotificationError();
      this.verify2FAOtp = true;
    },

    open2FAModal(){
      console.log("open2FAModal", this.twoFAUserDetails)
      const setUp2FADetails = {
        type: this.twoFAUserDetails.default_2fa_channel,
        action:"setup"
      };
      if (this.twoFAUserDetails && this.twoFAUserDetails.default_2fa_channel === 'google-auth' && this.twoFAUserDetails.is_google_auth_setup) {
        // Don't call QR code directly open OTP
        this.openOtpModel();
      } else {
        this.$store
          .dispatch(`accounts/${SETUP_OR_GENERATE_2FA_OTP}`, setUp2FADetails)
          .then(responseData => {
            if(responseData.type === 'google-auth') {
              this.googleAuthQRUrl = responseData.QRCodeImage;
            }
            this.googleAuthQRUrl = responseData.QRCodeImage;
            this.openOtpModel();
            // Open OTP Input Modal
            console.log("Response of SETUP_OR_GENERATE_2FA_OTP", responseData);
          })
          .catch(error => {
            console.log("Error of SETUP_OR_GENERATE_2FA_OTP", error);
          })
        // this.$store.dispatch(`accounts/${UPDATE_SUB_USER_DETAILS}`, this.twoFAUserDetails)
        // this.$store.dispatch(`accounts/${UPDATE_SUB_USER_DETAILS}`, {subUserID: this.twoFAUserDetails.subUserUuid, subUserDetails: this.twoFAUserDetails} )
      }
    },

    toggleSwitchFor2FASetup(val) {
      if(this.twoFAUserDetails.default_2fa_channel !== "") {
        // this.twoFAUserDetails.is_2fa_enabled = val
        const twoFAUserDetailsCopy = cloneDeep(this.userDetails);
        twoFAUserDetailsCopy.is_2fa_enabled = val;
        console.log("Backend request -- Updating 2fa ", val)
        this.$store
          .dispatch(`accounts/${UPDATE_SUB_USER_DETAILS}`, {subUserID: twoFAUserDetailsCopy.subUserUuid, subUserDetails: twoFAUserDetailsCopy} )
          .then(responseData => {
            this.$store.dispatch(`accounts/${FETCH_SUB_USER_DETAILS_BY_ID}`)
            // Open OTP Input Modal
            console.log("Response of SETUP_OR_GENERATE_2FA_OTP", responseData);
          })
          .catch(error => {
            console.log("Error of SETUP_OR_GENERATE_2FA_OTP", error);
          });
      } else {
        this.twoFAUserDetails.is_2fa_enabled = val
        console.log("No backend request -- Updating 2fa ", val)
      }
    },

    resendOTP() {
      const setUp2FADetails = {
        type: this.twoFAUserDetails.default_2fa_channel,
        action:"resend"
      };
      this.$store
        .dispatch(`accounts/${SETUP_OR_GENERATE_2FA_OTP}`, setUp2FADetails)
        .then(responseData => {
          console.log("Successfully resend OTP", responseData);
        })
        .catch(error => {
          console.log("Error while resending OTP", error);
        })
    },

    handleOtpChange(value) {
      this.otpData.otp = value;
    },
  },

  beforeDestroy() {
    this.$store.dispatch("notification/clear");
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <b-alert
      variant="danger"
      class="mt-3"
      v-if="notification.message"
      show
      dismissible
      @dismissed="resetNotificationError"
      >{{ notification.message }}</b-alert
    >

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-1" v-if="twoFAUserDetails">
              <div class="col-sm-12 col-md-3">
                <div class="row mt-2 ml-1">
                  <div class="d-flex align-items-center" >
                    <label class="mr-2" style="font-size: 1.2rem;" for="is_active">{{ $t("profile.2fa") }}</label>
                    <!-- <switches
                      v-model="twoFAUserDetails.is_2fa_enabled"
                      type-bold="true"
                      color="primary"
                      class="ml-1 mt-3 font-size-10"
                    ></switches> -->
                      <switches
                        :value="twoFAUserDetails.is_2fa_enabled"
                        type-bold="true"
                        color="primary"
                        class="ml-1 mt-3"
                        @input="toggleSwitchFor2FASetup($event)"
                      ></switches>
                  </div>
                </div>
                <div class="row mt-2 ml-1" v-if="twoFAUserDetails.is_2fa_enabled">
                  <div class="d-flex align-items-baseline justify-content-center" >
                    <span style="width: 200px;">{{ $t("profile.default_2fa_channel") }}</span>
                    <multiselect
                      style="width: 250px;"
                      v-model="twoFAUserDetails.default_2fa_channel"
                      :options="['email', 'phone', 'google-auth']"
                      class="sm-multiselect"
                      :allow-empty="false"
                      :searchable="false"
                      :multiple="false"
                      :taggable="false"
                    ></multiselect>
                    <button class="btn ml-4 btn-primary btn-sm text-nowrap" @click="open2FAModal()">{{ $t("button.update") }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <b-card :title="$t('profile.details')">
        </b-card>
      </div>
    </div>

    <b-modal
      size="md"
      id="verify2FAOtpModal"
      v-model="verify2FAOtp"
      :title="$t('profile.2fa')"
      title-class="text-dark font-18"
      no-close-on-backdrop
      hide-footer
    >
      <b-alert
        variant="danger"
        class="mt-3"
        v-if="notification.message"
        show
        dismissible
        @dismissed="resetNotificationError"
      > {{ notification.message }}</b-alert>

      <div v-if="twoFAUserDetails && twoFAUserDetails.default_2fa_channel == 'google-auth'" class="text-center">
        <img :src="`${googleAuthQRUrl}`" alt class="mt-2 mb-4" style="width: 20rem; object-fit: contain;"/>
      </div>
      <div>
        <div class="mb-2">
          <span v-if="twoFAUserDetails && twoFAUserDetails.default_2fa_channel == 'google-auth'">{{ $t('profile.2fa_msg') }}</span>
          <span v-else>{{ $t('profile.2fa_otp_msg') }}</span>
        </div>
        <form @submit.prevent="verifyOTP">

          <div class="form-group">
            <label for="otpVal">{{ $t('profile.otp') }}<span class="ml-1 text-danger">*</span></label>
            <!-- <input
              id="otpVal"
              type="text"
              class="form-control"
              placeholder="Enter One Time Password"
              v-model="otpData.otp"
              :class="{ 'is-invalid': submitted && $v.otpData.otp.$error }"
            /> -->
            <OtpInput
              id="otpVal"
              :input-classes="submitted && $v.otpData.otp.$error ? 'is-invalid otp-input form-control' : 'otp-input form-control'"
              separator="-"
              :num-inputs="6"
              :should-auto-focus="true"
              :is-input-num="true"
              @on-change="handleOtpChange"
            />
            <div
              v-if="submitted && !$v.otpData.otp.required"
              class="invalid-feedback"
            > {{ $t('profile.otp_required') }} </div>
          </div>
          <div class="mt-2">
            <a style="text-decoration: underline;" href="javascript:void(0);" @click="resendOTP">{{ $t('profile.resend_otp') }}</a>
          </div>
          <div class="text-right">
            <b-button class="mr-1" variant="light" @click="verify2FAOtp = false">{{ $t('button.close') }}</b-button>
            <button type="submit" class="btn btn-primary ml-1">{{ $t('profile.verify_otp') }}</button>
          </div>
        </form>
      </div>

    </b-modal>
  </Layout>
</template>

<style>

.font-size-num {
  font-size: 14px;
  margin-top: 4.5px;
  font-weight: bold !important;
}

.font-size {
  font-size: 16px;
  font-weight: bold !important;
}

.dropdown-container select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Safari, Chrome, Opera */
  -webkit-appearance: none;
}


.dropdown-container select::-ms-expand {
  display: none;
}
</style>